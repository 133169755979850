import {
  ContainerMFE,
  FeatureFlagKeyValues,
  SectionHeader,
  useGetFeatureFlags
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import {
  LookingForSomethingElse,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useEffect, useMemo } from 'react';
import ManageOrdersSection from 'src/components/ManageOrdersSection';
import MoreOptions from 'src/components/MoreOptions';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { ScreenName, ScreenPath } from 'src/utils/constants';
import { OrdersScreenDisplayed } from './analytics';
import { Header } from './styles';

export const OrdersPage = (): JSX.Element => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const featureFlagClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );

  const { originalFeedbackExperience, isLoading } = useGetFeatureFlags({
    getClient: featureFlagClient,
    keys: [FeatureFlagKeyValues.originalFeedbackExperience]
  });

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isLoading;

  useEffect(() => {
    publishEvent(OrdersScreenDisplayed);
  }, []);

  return (
    <ContainerMFE data-testid="orders-page">
      <Header>
        <SectionHeader
          title={translate('orders.ordersLabel', 'Orders')}
          subtitle={translate(
            'orders.viewAndManagePurchase',
            'View and manage your purchases'
          )}
        />
      </Header>
      <ManageOrdersSection />
      <MoreOptions />
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: ScreenName,
            screenPath: ScreenPath
          }}
          translate={translate}
        />
      )}
    </ContainerMFE>
  );
};
