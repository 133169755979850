import { publishEvent } from '@hpx-core-experiences/react-my-account-commons/dist';
import { IconMonitorSearch, IconShoppingCartCheckmark } from '@veneer/core';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { URLS } from 'src/utils/urls';
import {
  GuestOrdersHyperLinkClicked,
  ViewOrderHistoryHyperLinkClicked
} from './analytics';
import { Card, ClickableLabel, Container, Link } from './styles';

export default function ManageOrdersSection() {
  const { translate } = useDependencyManagerContext();

  const contents = [
    {
      title: {
        content: translate('orders.orderHistory', 'Order history'),
        postIcon: <IconShoppingCartCheckmark />
      },
      rows: [
        {
          content: translate(
            'orders.cardOrderHistory',
            'View and manage your purchases, along with accessing order tracking to your orders that were made while signed in.'
          ) as unknown as string,
          index: 0
        },
        {
          content: (
            <Link>
              {
                translate(
                  'orders.viewOrderHistory',
                  'View order history'
                ) as unknown as string
              }
            </Link>
          ),
          data: {
            href: URLS.OrderHistory,
            onClick: () => publishEvent(ViewOrderHistoryHyperLinkClicked)
          },
          index: 1
        }
      ]
    },
    {
      title: {
        content: translate('orders.guestOrder', 'Guest orders'),
        postIcon: <IconMonitorSearch />
      },
      rows: [
        {
          content: translate(
            'orders.findYourStatus',
            'Find your order status on purchases that were made while not signed in.'
          ) as unknown as string,
          index: 0
        },
        {
          content: (
            <Link>
              {
                translate(
                  'orders.findGuestOrder',
                  'Find guest order'
                ) as unknown as string
              }
            </Link>
          ),
          data: {
            href: URLS.GuestOrders,
            onClick: () => publishEvent(GuestOrdersHyperLinkClicked)
          },
          index: 1
        }
      ]
    }
  ];
  return (
    <Container data-testid="container-card">
      {contents.map((content, index) => (
        <ClickableLabel
          key={index}
          href={content.rows[1].data?.href}
          target="_blank"
          rel="noreferrer"
          onClick={content.rows[1].data?.onClick}
        >
          <Card
            rows={content.rows}
            showDivider={false}
            rowsColorChangeEnabled={false}
            title={content.title}
          />
        </ClickableLabel>
      ))}
    </Container>
  );
}
