import { Card as VCard } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import tokens from '@veneer/tokens';
import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist';
import styled from 'styled-components';

export const ClickableLabel = styled.a`
  min-width: 324px;
  width: 50%;
  cursor: pointer;

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  > div {
    padding: 0px;
  }
`;

export const Link = styled.p`
  color: ${tokens.color.hpBlue6};
`;

export const Card = styled(VCard)`
  & {
    height: 100%;
  }

  label {
    cursor: pointer;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px;
    height: 100%;

    div > div {
      padding: 0px 16px;
      min-height: 24px;
    }

    div:nth-child(2) {
      display: flex;
      min-height: 24px;
      justify-content: end;
      margin-bottom: 8px;
    }

    div:nth-child(3) {
      display: none;
    }
  }

  &:hover {
    background-color: ${tokens.color.gray1};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: ${MainTheme.breakpoints.mdMax}) {
    flex-direction: column;
    align-items: center;
  }
`;
