import { IconTools } from '@veneer/core';
import tokens from '@veneer/tokens';

import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
`;

export const StyledIconTools = styled(IconTools)`
  && {
    color: ${tokens.color.gray6};
    height: ${tokens.layout.size8};
    width: ${tokens.layout.size8};
  }
`;
